import styled from 'styled-components';
export const Section = styled.div`


padding: 20px 20px 20px 20px;
  height: 100%;
  border-radius: 25px;
  margin-top:50px;

`;
export const Content = styled.div`

background: #303330;
padding: 20px 20px 20px 20px !important;
  height: 100% !important;
  border-radius: 25px !important;
  justify-content: center !important;
  margin: auto;
  width: 1000px;


`;

export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "#d9eee8" : " white"};
  color: ${props => props.primary ? "white" : "#999"};
  font-size: 1.5em;
  height: 60px;
  width:200px;
  margin: 1.5em;
  padding: 0.25em 1em;
  border: 1px solid;
  border-radius: 25px;
 
 
`;
export const Title = styled.h1`
  font-size: 70px;
  color: #72daab;
  padding: 20px 20px 20px 20px;

`;
export const Titleh2 = styled.h2`
  font-size: 60px;
  color: #72daab;
  padding: 20px 20px 20px 20px;

`;

export const Titleh3 = styled.h3`
  font-size: 25px;
  padding: 20px 20px 20px 20px;
  color: white;



`;
export const Subtitle = styled.p`
  font-size: 20px;
  font-weight: normal;
  color: white;
  line-height: 1.5em;
  padding: 20px 20px 20px 20px;

`;

export const Image = styled.img`
width: 100%;
height: 100%;
position: relative;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

`;

const media = {
  desktop: '(min-width: 992px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  mobile: '(max-width: 767px)',
};
export const ResponsiveContent = styled(Content)`
  @media ${media.desktop} {
    border-radius: 25px;
    padding: 20px;


  }

  @media ${media.tablet} {
    border-radius: 25px;
    padding: 16px;
    width: 750px;

  }

  @media ${media.mobile} {
    border-radius: 25px;
    padding: 12px;
    width: 450px;

  }
`;
export const ResponsiveTitle = styled(Title)`
  @media ${media.desktop} {
    font-size: 60px;
    line-height: 2em;

  }

  @media ${media.tablet} {
    font-size: 50px;
    line-height: 1.5em;

  }

  @media ${media.mobile} {
    font-size: 36px;
    line-height: 1.5em;

  }
`;
export const ResponsiveTitleh2 = styled(Titleh2)`
  @media ${media.desktop} {
    font-size: 50px;
    line-height: 2em;

  }

  @media ${media.tablet} {
    font-size: 42px;
    line-height: 3em;

  }

  @media ${media.mobile} {
    font-size: 30px;
    line-height: 1.5em;

  }
`;

export const ResponsiveImage = styled(Image)`
  @media ${media.desktop} {
      width: 700px;
      height: 400px;
      margin-top: 200px;
  }

  @media ${media.tablet} {
    width: 600px;
      height: 300px;
      margin-top: 150px;
  }

  @media ${media.mobile} {
    width: 400px;
      height: 200px;
      margin-top: 150px;
  }
`;
export const ResponsiveSubtitle = styled(Subtitle)`
  @media ${media.desktop} {
    font-size: 20px;

  }

  @media ${media.tablet} {
    font-size: 16px;

  }

  @media ${media.mobile} {
    font-size: 14px;

  }
`;

export const ResponsiveTitleh3 = styled(Titleh3)`
  @media ${media.desktop} {
    font-size: 50px;
    line-height: 2em;
    

  }

  @media ${media.tablet} {
    font-size: 42px;
    line-height: 3em;

  }

  @media ${media.mobile} {
    font-size: 30px;
    line-height: 1.5em;

  }
`;