import React from 'react';
import './search-box.styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'




export const SearchBox = ({className, placeholder, onChangeHandler}) => (
     
        
       
        <input id='searchbox'
                   className={`searchbox ${className} `}
                   
                    type='Enter your domain name' 
                    placeholder={placeholder}
                    onChange={onChangeHandler}                    
            />
            
         
             );

            

//<FontAwesomeIcon className='searchicon' icon="fa-solid fa-magnifying-glass" bounce size="3x" style={{color: "#c7edf0",}} />