
import styled from 'styled-components';
export const Section = styled.div`
display: grid;
  grid-template-columns: 1fr;

  padding: 150px 20px;
  height: 100vh;
    width: 100%;
  border-radius: 25px;
  margin-top:75px;

`;
export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "#d9eee8" : " white"};
  color: ${props => props.primary ? "white" : "#999"};
  font-size: 1.5em;
  height: 60px;
  width:200px;
  margin: 1.5em;
  padding: 0.25em 1em;
  border: 1px solid;
  border-radius: 25px;

`;
export const Title = styled.h2`
  font-size: 45px;
color: #fff;
 
`;

export const Titleh3 = styled.h3`
  font-size: 75px;
  color: #5EBE62;


`;
export const Subtitle = styled.p`
  font-size: 20px;
  font-weight: normal;
  color: #fff;
  width:400px;
  height: 218px;

  
`;

