import styled from 'styled-components';
export const Section = styled.div`

background: #ffffff;
  padding: 0px 10px;
  text-align: center;
  border: 1px solid;
  border-radius: 15px;



`;

export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "#d9eee8" : " white"};
  color: ${props => props.primary ? "white" : "#999"};
  font-size: 1.5em;
  height: 60px;
  width:200px;
  margin: 1.5em;
  padding: 0.25em 1em;
  border: 1px solid;
  border-radius: 25px;
 
 
`;
export const Title = styled.h2`
  font-size: 45px;
  padding: 40px 40px 40px 0px;

 
`;

export const Titleh3 = styled.h3`
  font-size: 75px;
  color: #86cccc;

`;
export const Subtitle = styled.p`
  font-size: 20px;
  font-weight: normal;
  color: #434141;

  height: 218px;


  
`;