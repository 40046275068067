import { fireEvent } from '@testing-library/react';
import React from 'react'
import { ResponsiveSection,ResponsiveSubtitle, ResponsiveTitle, ResponsiveTitleh3, ResponsiveImage } from './snugfit.styles';

import { Button } from './snugfit.styles';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from '../../Grid/grid-component';
import Image from '../../../assets/BusinessRequirementsImage.svg';


const Humantouch = () => {
      return (
            <ResponsiveSection id='humantouch'>
                 
                 <ResponsiveTitleh3>
                 
                 2. A Solution, that's a Snug Fit for Your Business</ResponsiveTitleh3>
                  <ResponsiveSubtitle> Bridging the gap between your business needs and technical 
                  implementation aligning to your business goals and objectives.
</ResponsiveSubtitle> 
    <ResponsiveImage src={Image}/>

            </ResponsiveSection>
      );
};
    export default Humantouch;

    /*<Grid />
    
    <FontAwesomeIcon icon="fa-solid fa-puzzle-piece" style={{color: "#72daab",}} /> */ 
