import React from 'react'
import { Paragraph } from '../FooterLinks.styles';
import { Title} from '../FooterLinks.styles';
import { Subtitle} from '../FooterLinks.styles';
import { Titleh3 } from '../FooterLinks.styles';


import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container } from '../../../container/container.styles.js';

export const GDPR = () => (

<Container>   
        <Title>
        GDPR Policy</Title>

<Titleh3>Effective Date: July 2023</Titleh3>

<Titleh3> 1. Introduction </Titleh3>

<Paragraph>

At Sync Your Cloud we are committed to protecting the privacy and data security of our customers and users. This GDPR Policy outlines how we collect, process, store, and handle personal data in compliance with the General Data Protection Regulation (GDPR) (EU) 2016/679 and the UK Data Protection Act 2018 (DPA).

</Paragraph>

<Titleh3>2. Data Controller and Contact Information </Titleh3>
<Paragraph>

Sync Your Cloud is the data controller responsible for the processing of personal data. If you have any questions, concerns, or requests related to your personal data or this policy, please contact us at:

- Name: Sync Your Cloud
- Email: enquiries@syncyourcloud.io

</Paragraph>


<Titleh3>3. Types of Personal Data We Collect</Titleh3>
<Paragraph>

We may collect and process various types of personal data necessary for providing our cloud services and meeting contractual obligations. The data may include, but is not limited to:

- Name and contact information (e.g., email address, phone number)
- Billing and payment details
- IP addresses and device information
- Usage data and preferences
- Support and communication history
</Paragraph>

<Titleh3>4. Purpose of Data Processing</Titleh3>
<Paragraph>

We process personal data for the following purposes:

- Providing and managing our cloud services and contracts.
- Processing payments and invoicing for our services.
- Communication with customers regarding service updates, support, and marketing.
- Improving our services, user experience, and customer support.
- Complying with legal and regulatory obligations.
</Paragraph>

<Titleh3>5. Lawful Basis for Processing</Titleh3>
<Paragraph>

We process personal data under the following lawful bases:

- Performance of a contract: Processing necessary for fulfilling our contractual obligations to customers.
- Legitimate interests: Processing necessary for our legitimate business interests, provided it does not override individuals' rights and interests.
- Consent: Where required by applicable laws, we will obtain explicit consent for specific processing activities.
</Paragraph>
<Titleh3>6. Data Retention</Titleh3>
<Paragraph>

We will retain personal data only for as long as necessary to fulfill the purposes for which it was collected, as well as to comply with legal and regulatory requirements. Once data is no longer required, it will be securely deleted or anonymized.
</Paragraph>
<Titleh3>7. Data Security</Titleh3>
<Paragraph>

We implement appropriate technical and organizational measures to ensure the security, confidentiality, and integrity of personal data. This includes encryption, access controls, regular security assessments, and staff training.
</Paragraph>
<Titleh3>8. Sharing of Personal Data</Titleh3>
<Paragraph>

We may share personal data with trusted third-party service providers who assist us in delivering our cloud services (e.g., data hosting, payment processing). We ensure that these providers comply with GDPR and maintain the confidentiality and security of personal data.
</Paragraph>
<Titleh3>9. International Data Transfers</Titleh3>
<Paragraph>

As a UK-based company, we may transfer and store personal data outside of the UK or the European Economic Area (EEA). When doing so, we take all necessary measures to ensure data protection compliance, such as using standard contractual clauses or relying on the Privacy Shield Framework where applicable.
</Paragraph>
<Titleh3>10. Data Subject Rights</Titleh3>
<Paragraph>

Under GDPR, data subjects have certain rights regarding their personal data, including the right to access, rectify, erase, restrict processing, object to processing, and data portability. If you wish to exercise any of these rights, please contact us using the contact information provided in Section 2.
</Paragraph>
<Titleh3>11. Changes to this Policy</Titleh3>
<Paragraph>
We may update this GDPR Policy from time to time to reflect changes in our practices or for legal or regulatory reasons. The updated policy will be posted on our website with the effective date.
By using our cloud services, you acknowledge that you have read, understood, and agree to this GDPR Policy.

Sync Your Cloud
</Paragraph>
        
  </Container>
);
