import styled from 'styled-components';


export const MembershipLink = styled.button`
margin-top: 90px;
padding:40px 40px 40px 40px;
background: #202220;
        line-height: 1em;    
       border-radius: 40px;   
       color: white;
      position: relative;
       font-size: 1em;
       position: absolute;
       border: 3px solid #72daab !important;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

       &:hover {
        background-color: #bfe8c3;
      
        color: black;
     

          }
   



`;

// Media queries for responsiveness
const media = {
     desktop: '(min-width: 992px)',
     tablet: '(min-width: 768px) and (max-width: 991px)',
     mobile: '(max-width: 767px)',
   };
export const ResponsiveButton = styled(MembershipLink)`
  @media ${media.desktop} {
     margin-top: 100px;

  } 
  @media ${media.tablet} {
     margin-top: 150px;
     padding:30px;

  }
  @media ${media.mobile} {


     padding:25px;
  }
`;
