
import React from 'react';
import './directory.component';
import WebtoolItem from '../webtool-item/webtool-item.component';
import './directory.styles.scss'
const Directory = ({ webtools })  => {

    return (
        <div className='directory-container'>
                {webtools.map(( webtool ) => (
                    <WebtoolItem key={webtool.id} webtool={webtool} />
      
     ))}
     </div>
    );
    

                };
 export default Directory;