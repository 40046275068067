
    import React from 'react';
    import styled from 'styled-components';
    
    export const IrregularGridContainer = styled.div`
      display: grid;
      grid-template-columns: 400px 400px 400px ;
      width: 100%;
      height: 100%; /* Full screen height */
      box-sizing: border-box;
      padding-top: 100px;
      padding-bottom: 100px;
     
    `;
    export const Subtitle = styled.p`
  font-size: 25px;
  font-weight: normal;
  color: #fff;
padding: 25px 25px 25px 25px;
margin-right: 20%;
  line-height: 2em;
`;
// Media queries for responsiveness
const media = {
  desktop: '(min-width: 992px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  mobile: '(max-width: 767px)',
};
export const ResponsiveGrid = styled(IrregularGridContainer)`
  @media ${media.desktop} {

  } 
  @media ${media.tablet} {
    width: 60%;
    height: 60%;
  }

  @media ${media.mobile} {
    width: 40%;
    height: 60%;
  }
`;
export const ResponsiveSubtitle = styled(Subtitle)`
  @media ${media.desktop} {
    font-size: 25px;
  font-weight: normal;
  color: #fff;
padding: 25px 25px 25px 25px;
margin-right: 20%;
  align-text: center;
  line-height: 2em;

  } 
  @media ${media.tablet} {
    font-size: 20px;
  font-weight: normal;
  color: #fff;
padding: 25px 25px 25px 25px;
margin-right: 20%;
  align-text: center;
  line-height: 1.5em;
  }

  @media ${media.mobile} {
    font-size: 16px;
  font-weight: normal;
  color: #fff;
  line-height: 0.5em;
  }
`;