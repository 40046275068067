import React from 'react'
import {SearchBox} from '../Footer/FooterLinks/blog/search-box/search-box.component';



const SSLchecker = () => {
  return (
    <div>
        <SearchBox /> 
       <h1>Enter Domain Name</h1>
       
        </div>
  ) 
}
export default SSLchecker;
