import {React, useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { HeroSection, ResponsiveButton, ResponsiveSubtitle, ResponsiveTitle, Img } from './hero.styles.js';
import { HeroTitle } from './hero.styles.js';
import { HeroSubtitle } from './hero.styles.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



import './hero.scss'


    const Hero = () => {
      const [isActive, setIsActive] = useState(false);
      useEffect(() => {
        // Trigger the animation on component mount
        setIsActive(true);
      }, []);
      
      
      return(
      <HeroSection isActive={isActive}>
<ResponsiveTitle>Web and Mobile Advisory
        </ResponsiveTitle>
        

        <ResponsiveSubtitle>Pay As You Go Subscription - Cancel Anytime</ResponsiveSubtitle>  
    
        <HashLink smooth to={'/#howitworks'}><ResponsiveButton>  
        <FontAwesomeIcon icon="fa-solid fa-gear" beatFade size="xl" style={{color: "#fff",}} /> <br/><br/>
   
        How it Works
        
        </ResponsiveButton>
        
         </HashLink>     
      
      </HeroSection>
    );
  };
    export default Hero;