import React from 'react';
import './pagination.scss';


function Pagination({ postsPerPage, totalPosts, paginate }) {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    const handleClick = (event, pageNumber) => {
        event.preventDefault();
        paginate(pageNumber);
        window.scrollTo(0, 0);
    };

    return (
        <nav>
            <div className='pagination'>
                {pageNumbers.map(number => (
                    <li key={number} className='page-item'>
                        <a href='!#' className='page-link' onClick={e => handleClick(e, number)}>
                            {number}
                        </a>
                    </li>
                ))}
            </div>
        </nav>
    );
}

export default Pagination;