import React from 'react';
import styled from 'styled-components';

const IrregularGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px,1fr 1fr )); /* Irregular column widths */
  grid-gap: 5px;
  height: 100vh; /* Full screen height */
  padding: 20px;
  box-sizing: border-box;
`;

const GridItem = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const IrregularGrid = () => {
  return (
    <IrregularGridContainer>
      <GridItem>Security</GridItem>
      <GridItem>Remote Work</GridItem>
      <GridItem>AI</GridItem>
      <GridItem>Automation</GridItem>
      <GridItem>Item 5</GridItem>
      <GridItem>Item 6</GridItem>
    </IrregularGridContainer>
  );
};

