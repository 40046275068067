import React from 'react'
import Navigation from '../../routes/navigation/navigation.component';
import { Container } from '../container/container.styles';

import { StyledHeader} from '../styles/header.styles';



 

export const Header = () => {
    return (
    <StyledHeader><Navigation /></StyledHeader>
  )
}
