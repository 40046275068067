import styled from 'styled-components';

export const BackToTop = styled.button`

    padding:0px 40px 40px 40px;

        height:40px;
       background-color: #202220;
       color: white;
       border-radius: 8px;
       border: 3px solid #3cc88b;
       color:#fff;
       margin-top: 20px;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

       &:hover {
          background-color: white;
          color: black;


      }

`;
// Media queries for responsiveness
const media = {
    desktop: '(min-width: 992px)',
    tablet: '(min-width: 768px) and (max-width: 991px)',
    mobile: '(max-width: 767px)',
  };
  export const ResponsiveButton = styled(BackToTop)`
    @media ${media.desktop} {
     height:40px;
     padding:20px 40px 60px 40px;
     margin-top: 50px;
  
   
    }
  
    @media ${media.tablet} {
        margin-top: 50px;
        padding:20px 40px 60px 40px;
        height:25px;
        font-size: 12px;
    }
  
    @media ${media.mobile} {
        margin-top: 50px;
        padding:15px 30px 40px 30px;
        height:40px;
        font-size: 12px;
  
    }
  `;
  

