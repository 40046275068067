import { createContext, useState } from 'react';

import blogData from '../components/Footer/FooterLinks/blog/blogdata/BlogData.json';

export const BlogPostContext = createContext({
  blogPosts: [],
});

export const BlogPostsProvider = ({ children }) => {
  const [blogPosts, setBlogPosts] = useState(blogData);
  const value = { blogPosts };
  return (
    <BlogPostContext.Provider value={value}>
      {children}
    </BlogPostContext.Provider>
  );
};