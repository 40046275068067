
import styled, {keyframes} from 'styled-components';


export const Section = styled.div`


  height: 100%;
  width: 100%;
  border-radius: 25px;
  margin-top:75px;
  padding: 40px 0px 45px 0px;

`;
export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "#d9eee8" : " white"};
  color: ${props => props.primary ? "white" : "#999"};
  font-size: 1.5em;
  height: 60px;
  width:200px;
  margin: 1.5em;
  padding: 0.25em 1em;
  border: 1px solid;
  border-radius: 25px;

`;
export const Title = styled.h2`
  font-size: 45px;
 
`;

export const Titleh3 = styled.h3`
  font-size: 75px;
  color: #72daab;

`;
export const Subtitle = styled.p`
  font-size: 20px;
  font-weight: normal;
  color: #fff;
  padding-bottom: 50px;
  margin-top: 50px;


  
`;

export const Img = styled.img`
width: 90%;
height: 90%;



`;
export const IrregularGridContainer = styled.div`
      display: grid;
      grid-template-columns: 400px 400px 400px;
      width: 100%;
      height: 100%; /* Full screen height */
      box-sizing: border-box;
      padding-bottom: 100px;
      position: relative;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
      
     
    `;
  export const GridContainer = styled.div`

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 30px 20px 20px;
  
  `
  export const GridItem = styled.div `
  padding: 20px 0px 20px 0px;
  text-align: center;
  margin: 50px;

  
  
  `

// Media queries for responsiveness
const media = {
  desktop: '(min-width: 992px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  mobile: '(max-width: 767px)',
};
export const ResponsiveSection = styled(Section)`
  @media ${media.desktop} {



    width: 100%;
  border-radius: 25px;

  } 
  @media ${media.tablet} {


    width: 100%;
  border-radius: 25px;
  margin-top:50px;
  }

  @media ${media.mobile} {


    width: 100%;
  border-radius: 25px;
  margin-top:50px;
  }
`;
export const ResponsiveTitleh3 = styled(Titleh3)`
  @media ${media.desktop} {
    font-size: 40px;

  padding-bottom: 20px;
  } 
  @media ${media.tablet} {
    font-size: 35px;
    padding-bottom: 20px;

  }
  @media ${media.mobile} {
    font-size: 30px;
    padding-bottom: 20px;

  }
`;
export const ResponsiveTitle = styled(Title)`
  @media ${media.desktop} {
    font-size: 75px;

  } 
  @media ${media.tablet} {
    font-size: 60px;

  }
  @media ${media.mobile} {
    font-size: 30px;

  }
`;
export const ResponsiveSubtitle = styled(Subtitle)`
  @media ${media.desktop} {
    font-size: 25px;
    color: #fff;

  } 
  @media ${media.tablet} {
    font-size: 16px;

  }
  @media ${media.mobile} {
    font-size: 14px;

  }
`;
export const ResponsiveGrid = styled(GridContainer)`
  @media ${media.desktop} {
   

  } 
  @media ${media.tablet} {
    grid-template-columns: 50px 50px;
    flex-direction: column;
    align-items: center;

  }
  @media ${media.mobile} {
    flex-direction: column;
    align-items: center;

  }
`;


export const ResponsiveGridItem = styled(GridItem)`
  @media ${media.desktop} {
   

  } 
  @media ${media.tablet} {
    margin: 20px;

  }
  @media ${media.mobile} {
  margin: 10px;
  text-align: center;
  margin:0px;

  }
`





