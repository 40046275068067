import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { UserProvider } from './contexts/user.context';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { BlogPostsProvider } from './contexts/BlogPost.context';
import { Elements } from  '@stripe/react-stripe-js'
import { stripePromise } from './utils/firebase/stripe/stripe.utils';
import {  BacktoTopButton } from './components/BacktoTopButton/backtotop-component';
import './index.scss'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <BrowserRouter>
    <ScrollToTop>
    <UserProvider>
    <BlogPostsProvider>
    <Elements stripe={stripePromise}>
    <App />
    </Elements>
    </BlogPostsProvider>
    </UserProvider>
    </ScrollToTop>
    
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
