
import styled from 'styled-components';
export const Section = styled.div`

  background: #202220;
  padding: 25px 25px 25px 25px;
  height: 100%;
  border-radius: 25px;
  margin-top:50px;
  line-height: 1.5em;
  align-text: center;

`;
export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "#d9eee8" : " white"};
  color: ${props => props.primary ? "white" : "#999"};
  font-size: 1.5em;
  height: 60px;
  width:150px;
  margin: 1.5em;
  padding: 0.25em 1em;
  border: 1px solid;
  border-radius: 25px;

`;
export const Title = styled.h2`
  font-size: 40px;
  color: #72daab;
`;

export const Titleh3 = styled.h3`
font-size: 20px;
  line-height: 2em;
  color: white;

`;
export const Subtitle = styled.p`
  font-size: 12px;
  font-weight: normal;
  line-height: 4.5em;
  color: white;


`;


export const Paragraph1 = styled.p`
line-height: 2.5em;
padding: 20px;
font-size: 18px;


`;

export const Paragraph = styled.p`
line-height: 2.5em;
padding: 20px;
font-size: 14px;
color: white;


`;

export const Image = styled.img`
width: 400px;
height: 400px;

`;
const media = {
  desktop: '(min-width: 992px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  mobile: '(max-width: 767px)',
};

export const ResponsiveTitle = styled(Title)`
  @media ${media.desktop} {
    font-size: 40px;
    line-height: 3em;

  }

  @media ${media.tablet} {
    font-size: 36px;
    line-height: 3em;

  }

  @media ${media.mobile} {
    font-size: 27px;
    line-height: 1.5em;

  }
`;

export const ResponsiveImage = styled(Image)`
  @media ${media.desktop} {
      width: 400px;
      height: 400px;
  }

  @media ${media.tablet} {
      width: 300px;
      height: 300px;
  }

  @media ${media.mobile} {
      width: 200px;
      height: 200px;
  }
`;
