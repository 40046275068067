import React, { useContext } from 'react';
import { json, Outlet } from 'react-router-dom';
import {SearchBox} from './search-box/search-box.component';
import { useState, useEffect } from 'react';
import {CardList} from './card-list/card-list.component.jsx';
import {blogData} from './blogdata/BlogData'
import { Container } from '../../../container/container.styles';
import { BlogPostContext } from '../../../../contexts/BlogPost.context';
import Pagination from './pagination/pagination-component';





export const Blog = () => {
  const { blogPosts } = useContext(BlogPostContext);
  const [searchField, setSearchField] = useState('');
  const [filterBlogPosts, setFilterBlogPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 4; // Number of posts to display per page

  // Logic to paginate
  const paginate = pageNumber => setCurrentPage(pageNumber);

  // Logic to filter posts based on search field
  useEffect(() => {
    const filteredPosts = blogPosts.filter(post => 
      post.title.toLowerCase().includes(searchField.toLowerCase())
    );
    setFilterBlogPosts(filteredPosts);
  }, [blogPosts, searchField]);

  // Logic to calculate current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filterBlogPosts.slice(indexOfFirstPost, indexOfLastPost);

  const onSearchChange = (event) => {
    setSearchField(event.target.value);
  }; 

  return (
    <>
      <SearchBox
        className='search-box'
        onChangeHandler={onSearchChange}
        placeholder='Find...'
      />    
      <CardList blogPosts={currentPosts}/> 
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={filterBlogPosts.length}
        paginate={paginate}
      />
    </>
  );
};


//<div> <Outlet />
         
            
////<h1> I am Blog</h1>    <SearchBox />


//</div>
//useEffect(() => {
 // fetch('/Users/leesha/Documents/GitHub Projects/AddtoCart/AddtoCart/dashboard-webtools/src/routes/blog/blogdata/BlogData.json')
 // .then((response) => response.json())
 // .then((blogPosts) =>  setBlogPosts(blogPosts));
 // }, []);

 //var i = 0;
 // var txt = 'Find...'; /* The text */
  //var speed = 50; /* The speed/duration of the effect in milliseconds */

//function typeWriter() {
//if (i < txt.length) {
 //   document.getElementById("searchbox").innerHTML += txt.charAt(i);
 //   i++;
 //   setTimeout(typeWriter, speed);
 // }
//} 