
import styled, { css } from 'styled-components';

// Define a styled component with regular styles
const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

// Define media query breakpoints
const breakpoints = {
  small: '576px',
  medium: '768px',
  large: '992px',
  xlarge: '1200px',
};

// Create a reusable media query function
const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

// Use the media query function with styled components
const Title = styled.h1`
  font-size: 24px;
  ${media.medium`
    font-size: 36px;
  `}

  ${media.large`
    font-size: 48px;
  `}
`;

// Example usage in your React component
const MyComponent = () => {
  return (
    <Container>
      <Title>Hello, World!</Title>
      {/* Other content here */}
    </Container>
  );
};

export default MyComponent;
