import styled from "styled-components";

export const Buttonhowitworks = styled.button`
border-radius: 5px;
height: 60px;
width:200px;
border: 1px solid;
margin-bottom: 50px;

box-shadow: 0 0 2px rgba(0, 0, 0,.15);
cursor: pointer;
font-size: 22px;
font-weight: 700px:
padding: 15px 60px;
background-color: ${({bg}) =>  '#d9eee8'};
color: ${({color}) =>  '#000'};


&:hover {

    opacity: 0.9;
    transform: scale(0.98);
}

`