import { Container } from '../../../container/container.styles.js';
import { Section, Titleh3, Paragraph, Paragraph1, ResponsiveContent, ResponsiveTitle } from '../FooterLinks.styles';
import { Title } from '../FooterLinks.styles';
import { Subtitle } from '../FooterLinks.styles';
import { Button } from '../FooterLinks.styles';





    export const AboutUs = () => (
        <Container> 
        <ResponsiveTitle>About Us</ResponsiveTitle>
        <Titleh3>We Innovate for Efficiencies</Titleh3>
        <Paragraph>At Sync Your Cloud, we are passionate about leveraging the power of Artificial Intelligence (AI) and cloud technology to drive growth and success for businesses of all sizes. With a team of highly skilled cloud experts, we aim to deliver top-notch solutions tailored to your unique needs and goals. Our customer-centric approach ensures that you receive personalized attention and support every step of the way.
    Where Innovation meets efficiency, at Sync Your Cloud we are dedicated to providing cutting-edge cloud solutions to empower your business's digital transformation. Our comprehensive suite of cloud services is designed to enhance productivity, security, and scalability, enabling you to stay ahead in the rapidly evolving digital landscape.
 </Paragraph>

<Titleh3>   Our Services </Titleh3>
    <Paragraph>

<Titleh3>Cloud Infrastructure:</Titleh3> Build a robust and flexible cloud infrastructure that scales with your business demands. Our experts will guide you in selecting the right cloud provider and design an architecture that maximizes performance and cost-efficiency.

<Titleh3>Cloud Security:</Titleh3> Protect your valuable data and assets with our comprehensive cloud security automation and scans for vulnerabilities for your domains. From encryption to access control, we implement industry-leading practices to safeguard your business against cyber threats.

<Titleh3>Cloud Consulting: </Titleh3>Get expert advice on cloud strategy, optimization, and cost management. Our consultants will assess your current setup and provide actionable insights to maximize your cloud investment.
</Paragraph>


<Titleh3>Why Choose Sync Your Cloud?</Titleh3>

<Paragraph>

<Titleh3>Cutting-Edge Solutions:</Titleh3> Stay ahead in the digital era with our innovative and up-to-date cloud services tailored to your business needs.

<Titleh3>Trusted Expertise:</Titleh3> Our team of certified cloud professionals brings a wealth of experience and expertise to ensure the success of your cloud initiatives.

<Titleh3>Personalized Approach:</Titleh3> We believe in understanding your business thoroughly to provide tailored solutions that align with your objectives.

24/7 Support: Our dedicated support team is available round-the-clock to address any issues or concerns promptly.

Scalable and Cost-Efficient: Scale your cloud resources as your business grows, paying only for what you use to optimize costs.
</Paragraph>
<Titleh3>Contact Us</Titleh3>

<Titleh3>Ready to take your business to new heights with the power of the cloud?</Titleh3>


<Paragraph>
 Reach out to us today to schedule a consultation and discover how Sync Your Cloud can accelerate your digital transformation.

Website: www.syncyourcloud.io
Email: enquiries@syncyourcloud.io

</Paragraph>



 

      </Container>
    );
    
