
import { Routes, Route, Outlet } from 'react-router-dom';
import './routes/navigation/navigation.styles.scss';
import Home from './routes/home/home.component.jsx';
import Navigation from './routes/navigation/navigation.component';
import {Header} from './components/Header/header.component.jsx';
import { ThemeProvider } from 'styled-components';
import { HashLink } from 'react-router-hash-link';

import React, { useState, useEffect } from 'react';

//import './index.scss';
//routes
import Webtools from './routes/Webtools/webtools.component';
//import SignUpPage from './routes/SignUpPage';
//import {PricingPage} from './routes/PricingPage';
import { Authentication } from './components/authentication/authentication.component';
import GlobalStyle from './components/styles/globalStyles';
import {Footer} from './components/Footer/footer-component'
import {Blog} from './components/Footer/FooterLinks/blog/blog-component';
import  {HowitWorks}  from './components/Home_Components/3. Getting to know you/gettingtoknowyou-component';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowDown, faArrowUp, faBullseye, faChartPie, faChartSimple, faCloud, faFlag, 
    faGear, faHand, faHandPointUp, faList, faListCheck, faLock, 
    faMagnifyingGlassChart, faNewspaper, faPaw, faPersonCircleQuestion, 
    faPhone, faPuzzlePiece, faQuestion, faT, faX } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlass, faBars, faTimes} from '@fortawesome/free-solid-svg-icons';
import { faApple, faGoogle, faTwitter, faUikit } from '@fortawesome/free-brands-svg-icons'
import { Privacy } from './components/Footer/FooterLinks/Privacy/privacy-component';
import {CookiesConsent} from './components/Cookies/cookiesconsent.component.jsx';
import { AboutUs } from './components/Footer/FooterLinks/Aboutus/aboutus-component';
import {BlogPostDetails} from './components/Footer/FooterLinks/blog/blogpost-details/blogpost-details.component';
import { Terms } from './components/Footer/FooterLinks/Terms/terms-component';
import { GDPR } from './components/Footer/FooterLinks/GDPR/GDPR-component.jsx';
import { FAQs } from './components/FAQs/Faqs-component.jsx';
import {  BacktoTopButton } from './components/BacktoTopButton/backtotop-component';
import { Container } from './components/container/container.styles';
import { faEnvelope, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import {Membership} from './components/MembershipPlan/Membership-component'
import MembersPage from './components/MemberRestrictedContent/MembersPage-component';


library.add(faArrowDown,faArrowUp,faMagnifyingGlass, faTwitter, faBars, faTimes, faEnvelope, 
    faPersonCircleQuestion, faQuestion, faGear, faPenToSquare, faPuzzlePiece, faListCheck, faFlag, 
    faMagnifyingGlassChart, faChartPie, faChartSimple, faApple, faUikit,
    faLock, faBullseye, faEnvelope, faPhone, faHand, faCloud, faGoogle, faNewspaper, faX )


const theme = {
   colors: {
    header: '#ffffff',
    body:'#fff',
    footer: '#00000'
   },
}

const App = () => {
    console.log(window.innerHeight)
    console.log(window.innerWidth)

return (
    <ThemeProvider  theme = {theme}>
<GlobalStyle />

<Header/> 
<CookiesConsent/>
<Routes>

<Route index element={<Home />} />

<Route path='/blogPosts' element={<Blog />} />
<Route path='blogPosts/:id'
element={<BlogPostDetails/>}>
</Route>


<Route path='Webtools' element={<Webtools />} />
<Route path='HowitWorks' element={<HowitWorks />} />
<Route path='Privacy' element={<Privacy />} />
<Route path='aboutus' element={<AboutUs />} />
<Route path='terms' element={<Terms />} />
<Route path='membership' element={<Membership />} />

<Route path='gdpr' element={<GDPR />} />
<Route path='FAQs' element={<FAQs />} />
<Route path='Blog' element={<Blog />} />
</Routes> 
<BacktoTopButton/>
<Footer />

 </ThemeProvider>
);
}
export default App;
//<Route path='Pricingpage' element={<PricingPage />} />
/*<Route path='/membershipProducts' element={<MembershipProductsPage />} />
<Route path='membershipProducts/:id'
element={<MembershipProductDetails/>}>
</Route>


<Route path='memberspage' element={<MembersPage />} />

<Route path='auth' element= { <Authentication /> } />*/