
import styled from 'styled-components';
export const HumantouchSection = styled.div`

color: #72daab;
  border-radius: 25px;
  height: 110vh;
  padding: 40px 0px 45px 0px;



`;
export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "#d9eee8" : " white"};
  color: ${props => props.primary ? "white" : "#999"};
  font-size: 1.5em;
    width: 100%;

  padding: 0.25em 1em;
  border: 1px solid;
  border-radius: 25px;

`;
export const HumantouchTitle = styled.h2`
  font-size: 45px;
 
`;

export const HumantouchTitleh3 = styled.h3`
  font-size: 75px;
  color: #72daab;

`;
export const HumantouchSubtitle = styled.p`
  font-size: 20px;
  font-weight: normal;
  color: #fff;
  width:400px;
  margin: 30px 30px 30px 0px;

  
`;

export const P = styled.p`

font-size: 20px;
  font-weight: normal;
  color: #434141;





`;

export const Img = styled.img`
width: 80%;
height: 80%;
position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: 400px;

`;

// Media queries for responsiveness
const media = {
  desktop: '(min-width: 992px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  mobile: '(max-width: 767px)',
};
export const ResponsiveSection = styled(HumantouchSection)`
  @media ${media.desktop} {

    width: 100%;
  border-radius: 25px;
  margin-top: 50px;
  height: 150vh;

  } 
  @media ${media.tablet} {

    margin-top: 10px;
    width: 100%;
  border-radius: 25px;


  }

  @media ${media.mobile} {
    width: 100%;
  border-radius: 25px;
  margin-top: 0px;


  }
`;
export const ResponsiveTitleh3 = styled(HumantouchTitleh3)`
  @media ${media.desktop} {
    font-size: 40px;


  } 
  @media ${media.tablet} {
    font-size: 35px;

  }
  @media ${media.mobile} {
    font-size: 30px;

  }
`;
export const ResponsiveTitle = styled(HumantouchTitle)`
  @media ${media.desktop} {
    font-size: 75px;

  } 
  @media ${media.tablet} {
    font-size: 60px;

  }
  @media ${media.mobile} {
    font-size: 40px;

  }
`;
export const ResponsiveSubtitle = styled(HumantouchSubtitle)`
  @media ${media.desktop} {
    font-size: 25px;
    color:#fff;

  } 
  @media ${media.tablet} {
    font-size: 16px;

  }
  @media ${media.mobile} {
    font-size: 14px;

  }
`;
export const ResponsiveImage = styled(Img)`
  @media ${media.desktop} {
    margin-top: 300px;

  } 
  @media ${media.tablet} {
    margin-top: 300px;


  }
  @media ${media.mobile} {
    margin-top: 300px;

  }
`;
