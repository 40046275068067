
import { Link } from 'react-router-dom';
import { SocialMediaIcons } from '../../../../SocialMediaIcons/socialmedia-component';
import  {GridItem, IrregularGrid}  from '../FullScreenGrid.styles';
import { CardContainer, ResponsiveImage, ResponsiveTitleh3 } from './Card.styles';
import './card.styles.css';



const Card = ({blogPost}) =>  {
    const {id, title, category, description, createdAt, src} = blogPost;

    return (
        
            <CardContainer className='card-container' key={id}>
            <Link to={`/blogPosts/${id}`}>

            <ResponsiveImage className='blogpost-image' src={src}
                    alt={`blogpost ${title}`} 
    
            />
   
            <ResponsiveTitleh3>{title}</ResponsiveTitleh3>
            </Link>
            
                
              </CardContainer>   
              
        );
    };
export default Card;

//src={`https://robohash.org/${id}?set=set3&size=100x100`