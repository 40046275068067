import React from 'react';
import {CookieConsent, resetCookieConsentValue} from 'react-cookie-consent';


export const CookiesConsent = () => {

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="cookieConsent"
      style={{ background: '#333' }}
      buttonStyle={{ background: '#fff', color: '#333' }}
      expires={365}
      overlay
    >
    
      This website uses cookies to enhance the user experience. By using this website, you consent to the use of cookies.
    </CookieConsent>
  );
}


