
import {React, useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import { Section, ResponsiveTitleh3, ResponsiveButton, Section2, ResponsiveParagraph, Paragraph1, 
  Section4, Button,Img, ResponsiveSection, ResponsiveTitle, ResponsiveSubtitle, ResponsiveSection4, ResponsiveSection3, ResponsiveImg } from './Membership.styles.js';
import { Title } from './Membership.styles.js';
import { Subtitle } from './Membership.styles.js';
import { HashLink } from 'react-router-hash-link';
import sycImage  from '../../assets/MembershipPlanList.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Authentication } from '../authentication/authentication.component.jsx';
import { Container } from '../container/container.styles.js';
import { motion } from "framer-motion";



const email = 'enquiries@syncyourcloud.io';


const mailtoLink = `mailto:${email}`;
   export const Membership = () => {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update the state when the element is in the viewport
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        },
        {
          root: null, // Use the viewport as the root
          rootMargin: '0px', // No margin around the root
          threshold: 0.5, // Trigger when 50% of the element is visible
        }
      );
  
      // Start observing the element
      if (elementRef.current) {
        observer.observe(elementRef.current);
      }
  
      // Cleanup the observer when the component unmounts
      return () => {
        if (elementRef.current) {
          observer.unobserve(elementRef.current);
        }
      };
    }, []);
// Push member plan on left and add another section on right with animation of something (person with a membership form, etc, cog)
      return(
      <Container  ref={elementRef} isVisible={isVisible}> 
       
        
     
        
        <ResponsiveTitle>Monthly Membership</ResponsiveTitle>
        <ResponsiveParagraph>One Solution for a single product, mobile or web application that 
        requires aligning business objectives with technical implementation.
        </ResponsiveParagraph>
        <br/>
   
        <ResponsiveParagraph>Sync Membership will give you a detail 
        solution 
        design document and a roadmap for product, mobile or web application delivery:
        </ResponsiveParagraph>
        <br/>
            

        <ResponsiveImg src={sycImage} />
              
       <Link to='/faqs'>

        <ResponsiveButton> 
        FAQ's
      
        </ResponsiveButton>

      </Link>
      


       

        

      </Container>
 
   

    );
    }
    /* <ResponsiveSection2 id='membershipplans'>
      
                  
      </ResponsiveSection2>
   <Img src={sycImage} alt="AI" />
  
  <ul>
        <li>Access to our Cloud Assessment Tools </li>
        <li>Cloud Best Practices advice for:
          <ul>
            <li>Security</li>
          <li>Cloud Migrations</li>
          <li>Architecture and Infrastructure</li>
        </ul>   and 
        </li>
        <li>Step-by-Step Guides to achieve these Best Practices</li>
        <li>Next Steps and Action Plans for your Business</li>
      </ul> */
    