import React from 'react'
import { Paragraph } from '../Footer/FooterLinks/FooterLinks.styles';
import { Title} from '../Footer/FooterLinks/FooterLinks.styles';
import { Subtitle} from '../Footer/FooterLinks/FooterLinks.styles';
import { Titleh3 } from '../Footer/FooterLinks/FooterLinks.styles';


import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container } from '../container/container.styles.js';

export const FAQs = () => (

<Container>           
        <Title>
       Sync Your Cloud Membership FAQs</Title>

<Titleh3>Effective Date: July 2023</Titleh3>

<Titleh3> 1. What is Sync Your Cloud Membership? </Titleh3>

<Paragraph>

Sync Your Cloud Membership is a subscription-based service that offers exclusive access to a range of cloud technology resources, tools, and educational content. Our membership platform is designed to empower businesses and individuals with the knowledge and skills they need to harness the full potential of cloud technologies.


</Paragraph>

<Titleh3>2. What are the benefits of becoming a Sync Your Cloud Member? </Titleh3>
<Paragraph>

As a Sync Your Cloud Member, you will enjoy a variety of benefits, including:

- Early access to new cloud tools and features.
- Dedicated customer support to assist with any membership-related queries.
- Access to Sync Your Cloud resources and tools to grow your business and increase productivity.
- Consulting Advisory and Guidance with Action Plans

- Name: Sync Your Cloud
- Email: enquiries@syncyourcloud.io

</Paragraph>


<Titleh3>3. How much does the Sync Your Cloud Membership cost?</Titleh3>
<Paragraph>

We offer different membership tiers to cater to various needs. The pricing depends on the level of access and features you choose. Please visit our website or contact our sales team to get detailed pricing information.

</Paragraph>

<Titleh3>4. Can I cancel my Membership anytime?</Titleh3>
<Paragraph>

Yes, you can cancel your Membership at any time. We have a hassle-free cancellation process that allows you to manage your subscription from your member dashboard.

</Paragraph>

<Titleh3>5. Is there a free trial for the Sync Your Cloud Membership?</Titleh3>
<Paragraph>

Yes, we offer a limited-time free trial for new members. During the trial period, you can explore some of our basic features and get a taste of what our platform offers. At the end of the trial, you can choose to upgrade to a paid membership to access all premium features.

</Paragraph>
<Titleh3>6. What types of cloud technologies are covered in the membership?</Titleh3>
<Paragraph>

We will retain personal data only for as long as necessary to fulfill the purposes for which it was collected, as well as to comply with legal and regulatory requirements. Once data is no longer required, it will be securely deleted or anonymized.
</Paragraph>

<Titleh3>7. Can businesses and teams benefit from Sync Your Cloud Membership?</Titleh3>
<Paragraph>

Absolutely! Sync Your Cloud Membership is ideal for both individual learners and teams within organizations. We offer group membership plans and enterprise solutions tailored to the specific needs of businesses. Contact our sales team to discuss your organization's requirements.

</Paragraph>
<Titleh3>9. How often is new content added to the platform?</Titleh3>
<Paragraph>

We strive to keep our platform dynamic and up-to-date. New content, including courses, webinars, and resources, is added regularly to provide our members with the latest insights and knowledge in cloud technology.

</Paragraph>
<Titleh3>10. How do I get started with Sync Your Cloud Membership?</Titleh3>
<Paragraph>

To get started, simply visit our website and sign up for a membership plan that suits you best. Once you've completed the registration process, you'll gain instant access to our platform and all the resources it offers.

</Paragraph>

<Paragraph>
If you have any other questions or need further assistance, feel free to reach out to our support team at support@cloudtechmembership.com. We're here to help you make the most of your cloud technology journey!

Sync Your Cloud
</Paragraph>
        
  </Container>
);
