import styled from 'styled-components';

export const HeroSection = styled.div`

  text-align: center;
  width: 100%;
  border-radius: 50px;
  margin-top: 20px;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  
`;


export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: #202220;
  color: #fff;
  font-size: 1em !important;
  height: 50px !important;
  width:200px !important;
  margin: 1.5em;
  padding: 0.25em 1em;
  border: 3px solid #72daab !important;
  border-radius: 40px;

  &:hover {
        background-color: #bfe8c3;
      
        color: black !important;
          }
 
 
`;
export const HeroTitle = styled.h3`
  font-size: 75px;
  color: #72daab;
`;
export const HeroSubtitle = styled.h2`
  font-size: 30px;
  Padding: 15px;
  color: white; 
  font-weight: normal !important;
`;
export const Img = styled.img`
width: 80%;
height: 80%;
`;
// Media queries for responsiveness
const media = {
  desktop: '(min-width: 992px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  mobile: '(max-width: 767px)',
};
export const ResponsiveTitle = styled(HeroTitle)`
  @media ${media.desktop} {

  }

  @media ${media.tablet} {
    font-size: 60px;
  }

  @media ${media.mobile} {
    font-size: 40px;
  }
`;

export const ResponsiveSubtitle = styled(HeroSubtitle)`
  @media ${media.desktop} {
    font-size: 30px;
  }

  @media ${media.tablet} {
    font-size: 26px;
  }

  @media ${media.mobile} {
    font-size: 20px;
  }
`;

export const ResponsiveButton = styled(Button)`
  @media ${media.desktop} {
    line-height: 1em;
    font-size: 20px !important;
    width: 200px !important;
    height: 120px !important;


  }

  @media ${media.tablet} {
    border-radius: 25px;
    font-size: 15px !important;
    width: 150px !important;
    height: 100px !important;


  }

  @media ${media.mobile} {
    border-radius: 25px;
    line-height: 1em;
    font-size: 14px !important;
    width: 150px !important;
    height: 90px !important;


  }
`;

 /*{props => props.primary ? "#d9eee8" : " white"};{props => props.primary ? "white" : "#999"};*/