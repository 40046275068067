import React from 'react';
import { Outlet } from 'react-router-dom';
import Directory from '../../components/directory/directory.component';
import SSLchecker from '../../components/SSLchecker/sslcheck-component';
const Webtools = () => {
    const Webtools = [
{
  "id": 1,
  "title": "Check SSL",
  "imageUrl": "https://i.ibb.co/cvpntL1/hats.png",
},
{
  "id": 2,
  "title": "Microsoft O365 Security",
  "imageUrl": "https://i.ibb.co/px2tCc3/jackets.png",
},
{
  "id": 3,
  "title": "SEO AUDIT",
  "imageUrl": "https://i.ibb.co/0jqHpnp/sneakers.png",
},
{
  "id": 4,
  "title": "OWASP Compliance",
  "imageUrl": "https://i.ibb.co/GCCdy8t/womens.png",
},
{
  "id": 5,
  "title": "Web Security",
  "imageUrl": "https://i.ibb.co/R70vBrQ/men.png"
},
];
    return (
        <div> 
        <Outlet />
        <SSLchecker />
        <Directory webtools = {Webtools} />
       
     
        </div>
    )

};

export default Webtools;