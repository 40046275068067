import styled, {keyframes} from 'styled-components';

export const Section = styled.div`
 background: #f9ffff;
  padding: 25px 25px 25px 25px;
  height: 500vh;
  border-radius: 25px;
  margin-top:50px;
  line-height: 1.5em;
  align-text: center;
    

   
    
`;

export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  padding:5px 5px 5px 5px;
        line-height: 1em;
        background: #202220;
        border: 3px solid #72daab !important;
       color: #fff;
       border-radius: 30px;
  left: 50%;
  position: absolute;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
        font-size:30px !important;


        &:hover {
        background-color: #bfe8c3;
      
        color: black;
          }        
`;
export const Title = styled.h2`
font-size: 40px;
  color: #72daab;


`;

export const Titleh3 = styled.h3`
font-size: 20px;
  line-height: 4.5em;
  color: white;
`;
export const Subtitle = styled.p`
  font-size: 20px;
  font-weight: normal; 
`;

export const Paragraph1 = styled.p`
line-height: 2.5em;
padding: 20px;
font-size: 18px;
`;

export const Paragraph = styled.p`

font-size: 25px !important;
color: white;
`;

export const Img = styled.img`
height: 900px;
width: 500px;

position: relative;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: 400px;


`;
// Media queries for responsiveness
const media = {
  desktop: '(min-width: 992px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  mobile: '(max-width: 767px)',
};
export const ResponsiveSection = styled(Section)`
  @media ${media.desktop} {
   

 
  }

  @media ${media.tablet} {


  }

  @media ${media.mobile} {
 

  }
`;


export const ResponsiveTitle = styled(Title)`
  @media ${media.desktop} {
    font-size: 45px;
    padding-bottom: 10px;
  }

  @media ${media.tablet} {
   font-size: 40px;
   padding-bottom: 10px;
  }

  @media ${media.mobile} {
   font-size: 30px;
   padding-bottom: 10px;
  }
`;
export const ResponsiveTitleh3 = styled(Titleh3)`
  @media ${media.desktop} {
    font-size: 75px;

  }

  @media ${media.tablet} {
   font-size: 60px;

  }

  @media ${media.mobile} {
   font-size: 50px;

  }
`;
export const ResponsiveSubtitle = styled(Subtitle)`
  @media ${media.desktop} {
    font-size: 15px;
   
  }

  @media ${media.tablet} {
   font-size: 11px
  }

  @media ${media.mobile} {
   font-size: 7px
  }
`;
export const ResponsiveParagraph = styled(Paragraph)`
  @media ${media.desktop} {
    font-size: 20px !important;
  }

  @media ${media.tablet} {

    padding: 10px;
    font-size: 20px !important;

  }

  @media ${media.mobile} {

padding: 3px;
font-size: 15px !important;

  }
`;
export const ResponsiveButton = styled(Button)`
  @media ${media.desktop} {
    line-height: 1em;
    font-size: 18px !important;
    width: 200px !important;
    height: 70px !important;
    margin-top: 850px;



  }

  @media ${media.tablet} {

    font-size: 15px !important;
    width: 150px !important;
    height: 70px !important;
    margin-top: 750px;

  }

  @media ${media.mobile} {

    line-height: 1em;
    font-size: 16px !important;
    width: 150px !important;
    height: 70px !important;
    margin-top: 620px;

  }
`;
export const ResponsiveImg = styled(Img)`
  @media ${media.desktop} {
   

 
  }

  @media ${media.tablet} {
    height: 700px;
width: 400px;
      margin-top: 350px;

  }

  @media ${media.mobile} {
    height: 650px;
width: 350px;
      margin-top: 300px;



  }
`;

/*.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}*/ 