import styled, { keyframes } from 'styled-components';


export const Section = styled.div`


    margin-top: 50px;
    height: 100%;
    padding-top: 100px;
    width: 100%;
    text-align: center;
    border-radius: 25px;
    opacity: ${props => (props.isVisible ? 1 : 0)};
    transition: opacity 0.5s ease-in-out;
 
`;

export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "#d9eee8" : " white"};
  color: ${props => props.primary ? "white" : "#999"};
  font-size: 1.5em;
  height: 60px;
  width:200px;
  margin: 1.5em;
  padding: 0.25em 1em;
  border: 1px solid;
  border-radius: 25px;
 
 
`;
export const Title = styled.h2`
  font-size: 45px;
  color: #Ffffff;
`;

export const Titleh3 = styled.h3`
  font-size: 75px;
  color: #72daab;
`;

export const Subtitle = styled.p`
  font-size: 20px;
  font-weight: normal;
  color: #434141;
  height: 218px;
  padding: 25px 25px 25px 25px;
`;

export const Img = styled.img`
width: 80%;
height: 80%;

`;

// Media queries for responsiveness
const media = {
  desktop: '(min-width: 992px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  mobile: '(max-width: 767px)',
};

export const ResponsiveTitle = styled(Title)`
  @media ${media.desktop} {
    font-size: 45px;
    padding-bottom: 60px;
  }

  @media ${media.tablet} {
   font-size: 24px;
   padding-bottom: 40px;
  }

  @media ${media.mobile} {
   font-size: 20px;
   padding-bottom: 5px;
  }
`;
export const ResponsiveTitleh3 = styled(Titleh3)`
  @media ${media.desktop} {
    font-size: 75px;


  }

  @media ${media.tablet} {
   font-size: 60px;

  }

  @media ${media.mobile} {
   font-size: 40px;

  }
`;
export const ResponsiveSubtitle = styled(Subtitle)`
  @media ${media.desktop} {
    font-size: 15px;
  }

  @media ${media.tablet} {
   font-size: 11px
  }

  @media ${media.mobile} {
   font-size: 10px
  }
`;
