import React from 'react'
import { Outlet, Link } from 'react-router-dom';
import {  ResponsiveFooter } from './footer.styles';
import { FooterSubtitle} from './footer.styles';


export const Footer= () => {
  return (
    <>

<ResponsiveFooter>


      <Link className='nav-footer' to='/aboutus'>
  About Us
      </Link>


      <Link className='nav-footer' to='/privacy'>
        Privacy
      </Link>
      <Link className='nav-footer' to='/terms'>
        Terms and Conditions
      </Link>
      <Link className='nav-footer' to='/gdpr'>
        GDPR
      </Link>

     <FooterSubtitle> © SyncYourCloud 2024
      </FooterSubtitle></ResponsiveFooter></>
  )
}

/**  <Link className='nav-footer' to='/blogPosts'>
        Blog
      </Link> */