import React from 'react';
import './webtool-item.styles.scss'

const WebtoolItem = ({ webtool }) => {
    const { imageUrl, title} = webtool;

    return(

        <div className='webtool-container'>
        <div className='background-image' 
        style={{
            backgroundImage: `url(${imageUrl})`,

        }}
        />

<div className='webtool-dashboard-container'>
  
<h2>{title}</h2>
<p> Add to Sync Tool</p>
</div>
</div>

    )
}

export default WebtoolItem
// Webtool item used to display each webtool for