import Card from '../card/card.component';
import {IrregularGridContainer} from './CardList.styles.js'





export const CardList = ({blogPosts}) => (
 
<>
        <IrregularGridContainer >
            {blogPosts.map((blogPost) =>  {                       
            return <Card key={blogPost.id} blogPost={blogPost}/>;
            })}
        </IrregularGridContainer>

      </>
);