import blogData  from "../blogdata/BlogData.json";
import { useParams } from "react-router";
import { Container } from "../../../../container/container.styles.js";
import { Section, ResponsiveSubtitle, ResponsiveTitle, Titleh3,Titleh2, ResponsiveImage, ResponsiveTitleh2, ResponsiveContent } from '../blogpost-details/blogpost-details.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';



export const BlogPostDetails = () => {
  const { id } = useParams();
    const blogPosts = blogData;
    const blogPost = blogPosts.find(( blogPost) => {
      return id == blogPost.id;
    });
    if (!blogPost) {
      return <h3>Loading...</h3>;
    }
    const { title, category, createdAt, src, content} = blogPost;

  return (

    <>


        <ResponsiveContent>
        <ResponsiveImage src={src} /> 
        <Titleh3>{ category } </Titleh3>
       
      <ResponsiveTitle> { title } </ResponsiveTitle>
        <Titleh3>{createdAt}</Titleh3>
        {content.map((content) => {
              switch (content.type) {
                case 'heading':
                    return <ResponsiveTitleh2 >{content.text}</ResponsiveTitleh2>;
                  case 'sub-heading':
                    return <Titleh3 style={{ marginTop: '20px' }}>{content.text}</Titleh3>;
                  case 'paragraph':
                    return <ResponsiveSubtitle style={{ marginBottom: '10px' }}>{content.text}</ResponsiveSubtitle>;
                    case 'bulletPoints':
                  return (
                    <ul>
                      {content.items.map((item, i) => (
                        <ResponsiveSubtitle><li key={i}>{item}</li></ResponsiveSubtitle>
                      ))}
                    </ul>
                  );
                default:
                  return null;
              }
            })}
            </ResponsiveContent>
            </>

  );
}

;

/* 
 <Link to="https://twitter.com/X"><FontAwesomeIcon icon="fa-brands fa-twitter" size="2x" color="#86cccc" fade/></Link>*/
