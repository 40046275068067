import React from 'react'
import { ResponsiveTitle, ResponsiveSection, ResponsiveImage, ResponsiveSubtitle, ResponsiveTitleh3, Button} from './Onboarding.styles';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  WhyUs from '../../../assets/WhyUs.svg';



const Agility = () => (
      <ResponsiveSection id='agility'>  
      <ResponsiveTitleh3> 
            Why Us? 
    </ResponsiveTitleh3>
 
        <ResponsiveSubtitle> Harness next-gen solutions, including Generative AI with guidance and actionable next steps. Our advisory anticipates rethinking your existing technology stack, future predictions and insights. Architecting and designing technology solutions with agility in mind, every strategy includes an adaptive approach. 

        </ResponsiveSubtitle>     <ResponsiveImage src={WhyUs}/>

       
     </ResponsiveSection>
);
    export default Agility;
   //  <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-chart"  style={{color: "#7fe19c",}} />
   
   
   //<HashLink smooth to={'/#bestpractices'}><FontAwesomeIcon icon="fa-solid fa-arrow-down"  beat size='6x' style={{color: "#67c8d5",}} /></HashLink>