import React, { useState } from 'react';
import { ResponsiveButton } from './backtotop.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const BacktoTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', handleScroll);

  return (
    <ResponsiveButton
  classname={`backtotopbutton ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
    >
      <FontAwesomeIcon icon="fa-solid fa-arrow-up" 
      size="2xl" beat style={{color: "#63E6BE",}} /> 
      <br/> 

    </ResponsiveButton>
  );
};


