import  { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle `

* {
   box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'oxygen';
}

body {
    background: #202220;
    color: hsl(192,100%, 9%);
  margin: 0;
  font-family: 'Oxygen';
  height: 200vh;
  padding: 50px;
}

a {
  text-decoration: none;
  color: rgb(101, 100, 100);
}

p { opacity:0.6;
line-height: 1.5;}


`;





    

    export default GlobalStyle;


   // ${({theme }) => theme.colors.body}




