import React from 'react';
import styled from 'styled-components';

export const IrregularGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));; /* Irregular column widths */
  grid-gap: 1px;
  height: 100%; /* Full screen height */
  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 100px;
  
 

`;

/*  */