import styled from 'styled-components';
export const Section = styled.div`

  padding: 25px 20px 25px 25px;
  text-align: center;
margin-top: 300px;
    width: 100%;
   



`;

export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "#fff" : " white"};
  color: ${props => props.primary ? "white" : "#999"};
  font-size: 1.5em;
  height: 100vh;
  width: 100%;
  margin: 1.5em;
  padding: 0.25em 1em;
  border: 1px solid;
  border-radius: 25px;
`;
export const Title = styled.h2`
  font-size: 75px;
  padding: 25px;
  color: #fff;
`;

export const Subtitle = styled.p`
  font-size: 18px;
  padding: 25px;
  font-weight: normal;
  color: #434141;

  height: 100px;

  
`;
export const Titleh3 = styled.h3`
  font-size: 75px;
  color: #fff;

`;
// Media queries for responsiveness
const media = {
  desktop: '(min-width: 992px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  mobile: '(max-width: 767px)',
};
export const ResponsiveSection = styled(Section)`
  @media ${media.desktop} {
    padding: 25px 20px 25px 25px;
  text-align: center;
  height: 50vh;
    width: 100%;
  }

  @media ${media.tablet} {
    padding: 25px 20px 25px 25px;
  text-align: center;
  height: 40vh;
    width: 100%;
    margin-top: 250px;

    
  }

  @media ${media.mobile} {
    padding: 25px 25px 25px 25px;
  text-align: center;
  height: 35vh;
    width: 100%;
    margin-top: 200px;

  }
`;

export const ResponsiveTitle = styled(Title)`
  @media ${media.desktop} {
    font-size: 60px;
  padding: 25px;
  color: #fff;
  }

  @media ${media.tablet} {
    font-size: 55px;
  padding: 20px;
  color: #fff;
  }

  @media ${media.mobile} {
    font-size: 30px;
  padding: 15px;
  color: #fff;
  }
`;

export const ResponsiveSubtitle = styled(Subtitle)`
  @media ${media.desktop} {
    font-size: 18px;
  padding: 25px;
  font-weight: normal;
  color: #fff;
  height: 100px;
  }

  @media ${media.tablet} {
    font-size: 14px;
  padding: 20px;
  font-weight: normal;
  color: #fff;
  height: 80px;
  }

  @media ${media.mobile} {
    font-size: 12px;
  padding: 15px;
  font-weight: normal;
  color: #fff;
  height: 70px;
  }
`;

