
import React, { useState, useEffect } from 'react';
import { Fragment, useContext } from 'react'
import { Outlet, Link, redirect } from 'react-router-dom';
import {ReactComponent as Logo} from '../../assets/logoV2.svg';
import { UserContext } from '../../contexts/user.context';
import { SignOutUser } from '../../utils/firebase/firebase.utils';
import { HashLink } from 'react-router-hash-link';
import { Button } from '../../components/button/button.component';
import { Blog } from '../../components/Footer/FooterLinks/blog/blog-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from "framer-motion";
import './navigation.styles.scss'
import { Authentication } from '../../components/authentication/authentication.component';
import App from '../../App';
import Home from '../home/home.component';


const Navigation = () => {
  const { currentUser } = useContext(UserContext);

  const [isMobile, setIsMobile] = useState(false);

  const handleToggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };

  const handleMobileMenuItemClick = () => {
    setIsMobile(false);
  };

    return (
      <Fragment className={`navigation ${isMobile ? 'mobile' : ''}`}>
        <Link className='logo-container' to='/' >
            <Logo className='logo' />
            </Link> 
         
            <div className='nav-links'> 
            {currentUser ? ( 
              <h1> Welcome {currentUser.email.split('@')[0]}!
                    <span className='nav-link' onClick ={SignOutUser}> SIGN OUT</span> </h1>
                    ) : ( 
<></>
                  )}
                  <Link  to={'/Blog'} >
                      <motion.div className='nav-link-blog'
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
    >
               BLOG              
                </motion.div>
                </Link>
                  <HashLink  smooth to={'/#contact'} >
                  <motion.div className='nav-link-contact'
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
    >
                 
                  
                GET IN TOUCH
                
                
                </motion.div>
                </HashLink> 
      
                <Link  to={'/membership'} >
                      <motion.div className='nav-link-membership'
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
    >
                MEMBERSHIP               
                </motion.div>
                </Link>  

                </div> 

                <div className="mobile-menu-icon" onClick={handleToggleMobileMenu}>
                {isMobile ? <FontAwesomeIcon icon="fa-solid fa-x"   size="lg" style={{color: "#70f0c5",}} /> : 
                <FontAwesomeIcon icon="fa-solid fa-bars"  size="lg" style={{color: "#70f0c5",}} />} 
      </div>
     
      {isMobile && (
        <div className="mobile-menu">
        {currentUser ? ( 
              <h1> Welcome {currentUser.email.split('@')[0]}!
                    <span className='nav-link' onClick ={SignOutUser}> SIGN OUT</span> </h1>
                    ) : (<></>
                  )}
          
                  <div className="mobile-menu-item" onClick={handleMobileMenuItemClick}>
                  <HashLink className='nav-link-membership-mobile' to={'/membership'} >
                MEMBERSHIP
                </HashLink>
                </div>
                  <div className="mobile-menu-item" onClick={handleMobileMenuItemClick}>
                  <HashLink className='nav-link-contact-mobile' smooth to={'/#contact'} >
                GET IN TOUCH
                </HashLink> 
                </div>
                <div className="mobile-menu-item" onClick={handleMobileMenuItemClick}>
                  <Link className='nav-link-blog-mobile' to={'/Blog'} >
                BLOG
                </Link> 
                </div>
           
              
        </div>
      )}

                

                
                 <Outlet />
      </Fragment>
    );
    };
    export default Navigation;

    /*                  <Link  to={'/memberspage'} >
                      <motion.div className='nav-link-login'
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
    >
                LOGIN                
                </motion.div>
                </Link>  
                
                
                
                     <div className="mobile-menu-item" onClick={handleMobileMenuItemClick}>
                  <Link className='nav-link-login' to={'/memberspage'} >
                LOGIN
                </Link> 
                </div>*/ 