import styled from 'styled-components';


export const FooterSection = styled.div`

    bottom: 0;
    margin-top: 100px;
    background-color: #202220;
    padding-top: 40px;
    width: 100%;
    color: #fff;
    text-align: center;
  position: relative;

  
   
`;

  export const FooterSubtitle = styled.h2`
  font-size: 16px;
  color: #fff;
  padding: 50px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  text-align: center;

`;
// Media queries for responsiveness
const media = {
  desktop: '(min-width: 992px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  mobile: '(max-width: 767px)',
};
export const ResponsiveFooter = styled(FooterSection)`
  @media ${media.desktop} {
    font-size: 16px;
  }

  @media ${media.tablet} {
    font-size: 14px;
  }

  @media ${media.mobile} {
    font-size: 12px;
  }
`;