import styled, { theme } from "styled-components";

export const StyledHeader = styled.header`
padding: 40px 40px;


/*{( {theme}) => theme.colors.header};
 */
`





