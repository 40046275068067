import styled from 'styled-components';

export const CardContainer = styled.div`
display: flex;
flex-direction:column;
  border-radius: 8px;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  padding-bottom: 20px;
  opacity: 0.75;
  background: #303330;

 

`;
export const Titleh3 = styled.h3`
  font-size: 25px;
  line-height: 1.5em;
  text-align: center;
  color: white;
`;

export const Image = styled.img`
  width: 400px;
  height: 300px;
  border-radius: 25px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%);
  position: relative;


`;
// Media queries for responsiveness
const media = {
    desktop: '(min-width: 992px)',
    tablet: '(min-width: 768px) and (max-width: 991px)',
    mobile: '(max-width: 767px)',
  };
  export const ResponsiveImage = styled(Image)`
    @media ${media.desktop} {
       
    }
  
    @media ${media.tablet} {
        width: 400px;
        height: 300px;

    }
  
    @media ${media.mobile} {
      left: 35%;
        width: 300px;
        height: 200px;
    }
  `;

  export const ResponsiveTitleh3 = styled(Titleh3)`
  @media ${media.desktop} {
      font-size:25px;

  }

  @media ${media.tablet} {
      font-size: 20px;

  }

  @media ${media.mobile} {
    text-align: left;
      font-size: 12px;

  }
`;