import React from 'react'
import { Link } from 'react-router-dom';
import { ResponsiveSection, Titleh3 } from './contact.styles.js';
import { ResponsiveTitle } from './contact.styles.js';
import { Subtitle } from './contact.styles.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const email = 'enquiries@syncyourcloud.io';


const mailtoLink = `mailto:${email}`;

    const Contact = () => (
      <ResponsiveSection id='contact'>
         <ResponsiveTitle>Inspired? Connect with Us</ResponsiveTitle> 
         <a href={mailtoLink}>
         <FontAwesomeIcon icon="fa-regular fa-envelope" bounce size="4x" style={{color: "#75f5ca",}} />
         </a>         
      </ResponsiveSection>
    );
    export default Contact;