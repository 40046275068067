import {React, useEffect, useState, useRef} from 'react'
import { ResponsiveTitle, ResponsiveSubtitle, ResponsiveSection, Img, ResponsiveTitleh3, ResponsiveGrid, ResponsiveGridItem} from './gettingtoknowyou.styles';

import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



export const HowitWorks = () => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update the state when the element is in the viewport
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: '0px', // No margin around the root
        threshold: 0.5, // Trigger when 50% of the element is visible
      }
    );
    // Start observing the element
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    // Cleanup the observer when the component unmounts
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);
return(
      <ResponsiveSection id='howitworks' ref={elementRef} isVisible={isVisible}>
      
        <ResponsiveTitleh3>1. Getting to know You</ResponsiveTitleh3>
        <ResponsiveSubtitle>Addressing your technical challenges, and understanding your business goals we accelerate business growth with an action plan that works for you. Our thorough snapshot of your business includes but not limited to:
        </ResponsiveSubtitle>
    
        <ResponsiveGrid>
      <ResponsiveGridItem>
         <FontAwesomeIcon icon="fa-solid fa-bullseye" size="4x" style={{color: "#8DE3CE",}} />          
         <ResponsiveSubtitle>Business Goals</ResponsiveSubtitle>
      </ResponsiveGridItem>
      <ResponsiveGridItem>
         <FontAwesomeIcon icon="fa-brands fa-apple" size="4x" style={{color: "#8DE3CE",}}/>
         <ResponsiveSubtitle>Systems Analysis</ResponsiveSubtitle>
      </ResponsiveGridItem>
      <ResponsiveGridItem>
         <FontAwesomeIcon icon="fa-solid fa-chart-simple" size="4x" style={{color: "#8DE3CE",}} />
         <ResponsiveSubtitle>Performance Analysis</ResponsiveSubtitle>
      </ResponsiveGridItem>
      <ResponsiveGridItem>
         <FontAwesomeIcon icon="fa-solid fa-lock" size="4x" style={{color: "#8DE3CE",}} />
         <ResponsiveSubtitle>Security & Compliance Analysis</ResponsiveSubtitle>
      </ResponsiveGridItem>
      <ResponsiveGridItem>
         <FontAwesomeIcon icon="fa-brands fa-uikit" size="4x" style={{color: "#8DE3CE",}} />
         <ResponsiveSubtitle>UI and UX Analysis</ResponsiveSubtitle>  
      </ResponsiveGridItem>
      <ResponsiveGridItem>
         <FontAwesomeIcon icon="fa-solid fa-phone" size="4x" style={{color: "#8DE3CE",}} />
         <ResponsiveSubtitle>Priority Support</ResponsiveSubtitle>
      </ResponsiveGridItem>
     
    </ResponsiveGrid>
           
        
      </ResponsiveSection>
);
}
/*<Img src={sycImage}/> */ 