import React from 'react';
import { Outlet } from 'react-router-dom';
import Directory from '../../components/directory/directory.component';
import Hero from '../../components/Home_Components/1. Hero/hero-component';
import Humantouch from '../../components/Home_Components/4. Snug Fit/snugfit-component';
import { Container } from '../../components/container/container.styles';
import Yearsoftech from '../../components/25yearsoftech/25yearsoftech-component';
import Agility from '../../components/Home_Components/2. Onboarding/Onboarding-component';
import Startups from '../../components/Home_Components/AI_and_Automation/startups-component';
import Bestpractices from '../../components/BestPractices/Bestpractices-component';
import Contact from '../../components/Home_Components/6. Contact/contact-component';
import {MembershipLinkComponent} from '../../components/Home_Components/5. Membership Link/MembershipLink-component';
import {HowitWorks} from '../../components/Home_Components/3. Getting to know you/gettingtoknowyou-component';
import { HashLink } from 'react-router-hash-link';
import home from './home.styles.scss'
import {media} from '../../components/MediaQuery/mediaquery-component'
import { Button } from '../../components/Home_Components/3. Getting to know you/gettingtoknowyou.styles';
import StayConnected from '../../components/Home_Components/7. Stay Connected/StayConnected-component';




const Home = () => {
    const Webtools = [
{
  "id": 1,
  "title": "Web Monitoring In One Place",
  "imageUrl": "https://i.ibb.co/cvpntL1/hats.png",
},
{
  "id": 2,
  "title": "Get More Done in Less Time",
  "imageUrl": "https://i.ibb.co/px2tCc3/jackets.png",
},
{
  "id": 3,
  "title": "Insightful, Fast, Call to Action through Alerts",
  "imageUrl": "https://i.ibb.co/0jqHpnp/sneakers.png",
},
];
    return (
        <div style={home.container}> 
        <Outlet />
        <Container>
        <Hero />      
        <Agility />
        <HowitWorks/>
    
        <Humantouch />
        <MembershipLinkComponent />
       
        <Contact /> 
        
       

        </Container>
        </div>
    );
};
export default Home;

/*<StayConnected /> include in blog page*/