import React from 'react'
import { Link } from 'react-router-dom';
import { ResponsiveButton } from './MembershipLink.styles';



export const MembershipLinkComponent = () => (
<Link to={'/membership'}>
<ResponsiveButton  >
                See Membership Plan
                </ResponsiveButton>

</Link>


);

