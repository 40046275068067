import React from 'react'
import { Paragraph, Section, Paragraph1} from '../FooterLinks.styles';
import { Title} from '../FooterLinks.styles';
import { Subtitle} from '../FooterLinks.styles';

import { Titleh3 } from '../FooterLinks.styles';

import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container } from '../../../container/container.styles.js';

export const Privacy = () => (

<Container>  
        <Title>Privacy Policy </Title>
        
       <Paragraph>


Effective Date: July 2023

Our website address is: https://www.syncyourcloud.io

At Sync Your Cloud, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us when you use our cloud business and technology services. By using our services, you agree to the terms outlined in this Privacy Policy.

<Titleh3>1. Information We Collect</Titleh3>

1.1. Personal Information:When you sign up for our services or communicate with us, we may collect personal information such as your name, email address, phone number, billing information, and any other information you voluntarily provide.

1.2. Usage Information: We may collect non-personal information about how you use our services, including IP addresses, device information, browser type, access times, and the pages you visit.

1.3. Cookies and Similar Technologies: We use cookies and similar technologies to enhance your experience and analyze usage patterns on our website. You can manage your cookie preferences through your browser settings.

<Titleh3>2. How We Use Your Information</Titleh3>

2.1. Provision of Services: We use your personal information to provide and improve our cloud business and technology services, respond to your inquiries, and process your transactions.

2.2. Communication: We may use your contact information to send you important updates, newsletters, marketing materials, and other relevant information about our services. You can opt-out of marketing communications at any time.

2.3. Analytics: We analyze usage data to understand how our services are used and to improve our offerings, user experience, and customer support.

2.4. Legal Compliance: In certain circumstances, we may be required to process and disclose your information to comply with legal obligations or protect our rights, safety, or the rights of others.

<Titleh3>3. Data Security</Titleh3>

We implement appropriate technical and organizational measures to safeguard your personal information from unauthorized access, disclosure, alteration, and destruction. However, no data transmission or storage system can be guaranteed to be 100% secure, so we cannot guarantee the absolute security of your information.

<Titleh3>4. Third-Party Sharing</Titleh3>

We do not sell, trade, or rent your personal information to third parties for marketing purposes. However, we may share your information with trusted third-party service providers who assist us in delivering our services, subject to strict confidentiality agreements.

<Titleh3>5. International Data Transfers</Titleh3>

Your information may be processed and stored on servers located in different countries where data protection laws may differ. By using our services, you consent to the transfer of your information to these countries.



<Titleh3>6. Your Choices and Rights</Titleh3>

6.1. Access and Correction: You have the right to access and correct your personal information held by us. You can update your account information or request access by contacting us.

6.2. Data Retention: We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.

6.3. Opt-Out: You can opt-out of receiving marketing communications from us by following the instructions in our emails or contacting us directly.

<Titleh3>7. Children's Privacy</Titleh3>

Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected such information, please contact us, and we will take appropriate measures to delete it.

<Titleh3>8. Changes to this Privacy Policy</Titleh3>

We may update this Privacy Policy from time to time to reflect changes in our practices or for legal or regulatory reasons. We will notify you of any material changes through the contact information you provide or by posting a prominent notice on our website.

<Titleh3>9. Contact Us</Titleh3>

If you have any questions or concerns about our Privacy Policy or the data we hold about you, please contact us at:

Sync Your Cloud
enquiries@syncyourcloud.io


By using our cloud business and technology services, you acknowledge that you have read and understood this Privacy Policy.

</Paragraph>

 </Container>
);
