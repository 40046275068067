import React from 'react'
import { Section, Paragraph, Paragraph1, ResponsiveContent, ResponsiveTitle } from '../FooterLinks.styles';
import { Title} from '../FooterLinks.styles';
import { Subtitle} from '../FooterLinks.styles';
import { Titleh3 } from '../FooterLinks.styles';


import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container } from '../../../container/container.styles.js';

export const Terms = () => (

<Container>
           
        <ResponsiveTitle>Terms and Conditions</ResponsiveTitle>

        <Paragraph>Effective Date: July 2023

Welcome to Sync Your Cloud! These Terms and Conditions "Terms" govern your use of our cloud technology services. By accessing or using our services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please refrain from using our services.
</Paragraph>
<Titleh3>1. Definitions</Titleh3>

<Paragraph>
1.1. "Sync Your Cloud," "we," "our," or "us" refers to Sync Your Cloud the provider of cloud technology services.

1.2. "User," "you," or "your" refers to any individual or entity that uses or accesses our cloud technology services.

1.3. "Services" refers to all the cloud business and technology services provided by Sync Your Cloud.
</Paragraph>

<Titleh3>2. Use of Services</Titleh3>

<Paragraph>
2.1. Eligibility: By using our services, you represent and warrant that you have the legal capacity to enter into this agreement. If you are using our services on behalf of an organization, you further represent and warrant that you have the authority to bind that organization to these Terms.

2.2. Account Creation: To access certain features of our services, you may need to create an account. The information you provide during account registration must be accurate and up-to-date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.

2.3. Acceptable Use: You agree to use our services for lawful purposes and in accordance with these Terms. You shall not engage in any activity that interferes with or disrupts the functionality of our services or violates any applicable laws or regulations.

</Paragraph>
<Titleh3>3. Intellectual Property </Titleh3>

<Paragraph>
3.1. Ownership: All intellectual property rights, including but not limited to copyrights, trademarks, patents, trade secrets, and any other proprietary information related to our services, shall remain the exclusive property of [Your Company Name].

3.2. Limited License: Subject to compliance with these Terms, Sync Your Cloud grants you a limited, non-exclusive, non-transferable, revocable license to access and use our services for personal or internal business purposes.

</Paragraph>


<Titleh3>4. Fees and Payments</Titleh3>
<Paragraph>
4.1. Pricing: The fees for our services are specified on our website or as otherwise communicated to you. We reserve the right to modify our pricing at any time, and any changes will be effective upon notice.

4.2. Payment: You agree to pay all applicable fees as per the selected services and billing frequency. Payment methods will be specified on our website.

4.3. Taxes: You are responsible for any applicable taxes associated with the use of our services, as required by law.
</Paragraph>

<Titleh3>5. Data Privacy</Titleh3>

<Paragraph>

5.1. Data Collection: By using our services, you consent to the collection, use, and disclosure of your information as described in our Privacy Policy [Link to Privacy Policy].

5.2. Data Security: We implement reasonable security measures to protect your data; however, we cannot guarantee the complete security of your information. You are responsible for safeguarding your account credentials and maintaining the security of your devices.

</Paragraph>
<Titleh3>6. Termination</Titleh3>

<Paragraph>

6.1. Termination by You: You may terminate your account and discontinue using our services at any time.

6.2. Termination by Sync Your Cloud: We may suspend or terminate your access to our services if you violate these Terms or engage in any illegal or harmful activities.

</Paragraph>
<Titleh3>7. Modifications to the Services</Titleh3>

<Paragraph>

7.1. Updates: We may release updates, improvements, or new features to our services periodically.

7.2. Changes to Terms: We reserve the right to modify these Terms at any time. Updated Terms will be posted on our website, and your continued use of our services after the changes will signify your acceptance of the updated Terms.

</Paragraph>
<Titleh3>8. Disclaimer of Warranties</Titleh3>
<Paragraph>

8.1. OUR SERVICES ARE PROVIDED ON AN "AS-IS" AND "AS AVAILABLE" BASIS. WE MAKE NO WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
</Paragraph>

<Titleh3>9. Limitation of Liability</Titleh3>
<Paragraph>
9.1. TO THE MAXIMUM EXTENT PERMITTED BY LAW, SYNC YOUR CLOUD SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OF OUR SERVICES.
</Paragraph>

<Titleh3>10. Governing Law and Jurisdiction</Titleh3>
<Paragraph>

10.1. These Terms shall be governed by and construed in accordance with the laws of [Your Country/State/Province], without regard to its conflicts of law principles.

10.2. Any dispute arising from or relating to these Terms shall be subject to the exclusive jurisdiction of the courts in [Your City/Region], [Your Country/State/Province].
</Paragraph>

<Titleh3>11. Contact Us</Titleh3>

<Paragraph>
If you have any questions or concerns about these Terms and Conditions, please contact us at:

Sync Your Cloud
enquiries@syncyourcloud.io

By using our cloud technology services, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.

</Paragraph>

‍ 
      </Container>
);
