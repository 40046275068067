import { initializeApp } from "firebase/app";
import { 
getAuth,
signInWithPopup,
GoogleAuthProvider,
createUserWithEmailAndPassword,
signInWithEmailAndPassword,
signOut,
onAuthStateChanged } from 'firebase/auth';
import  {getFirestore, doc, getDoc, setDoc} from 'firebase/firestore'


const firebaseConfig = {
    apiKey: "AIzaSyDmc_w0SaKZD6tdO--35EaowPpx584rBcM",
    authDomain: "syc-tools.firebaseapp.com",
    projectId: "syc-tools",
    storageBucket: "syc-tools.appspot.com",
    messagingSenderId: "981612817606",
    appId: "1:981612817606:web:f72670bf954fcebf337bc7"
  };
  
  // Initialize Firebase

  export const firebaseApp = initializeApp(firebaseConfig);

  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account"

  });

  export const auth = getAuth();
  export const signInWithGooglePopup =  () => signInWithPopup(auth, provider);


  //Instantiate Firestore

  export const db = getFirestore();
  export  const createUserDocumentFromAuth = async (userAuth, additionalInformation = {}) => {
    if(!userAuth) return;
   const userDocRef = doc(db, 'users', userAuth.uid);
    const userSnapshot = await getDoc(userDocRef);
    if (!userSnapshot.exists()) {
      const {displayName, email } = userAuth;
      const createdAt = new Date ();
      try {
        await setDoc(userDocRef, {
          displayName,
          email,
          createdAt,
            ...additionalInformation,
        });
      } catch (error) {
        console.log ('error creating user', error.messsage);
      }
    }
    return userDocRef;
  };
  
  export const createAuthUserWithEmailAndPassword  = async (email, password) => {

    if (!email || !password) return;
    return await createUserWithEmailAndPassword (auth, email, password );
  };

  export const signInAuthUserWithEmailAndPassword = async(email, password) => {
      if (!email || !password) return;
      return await signInWithEmailAndPassword(auth, email, password);
  };

  export const SignOutUser =  async() => await signOut(auth);

  export const onAuthStateChangedListener = (callback) => onAuthStateChanged (auth, callback );


