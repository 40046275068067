
import styled from 'styled-components';
export const Section = styled.div`

  padding: 45px 45px  45px 45px;
  text-align: center;
  height: 45vh;
    width: 100%;
    background: #444645;
    border-radius: 25px;
 

`;

export const Button = styled.button`
    padding:20px 20px 20px 20px;
     
     line-height: 5em;
    background-color:#3cc88b;
    color: #fff;
    border: 1px solid grey; 
    border-radius: 8px;
 
     font-size:medium;


     &:hover {
       background-color: white;
       color: black;
       border: 1px solid black;

   }
`;
export const Title = styled.h2`
  font-size: 45px;
  padding: 25px;
  color: #fff;
`;

export const Titleh3 = styled.h3`
  font-size: 75px;
  color: #fff;

`;
export const Subtitle = styled.p`
  font-size: 18px;
  padding: 25px;
  font-weight: normal;
  color: #fff;

  height: 100px;

  
`;

// Media queries for responsiveness
const media = {
  desktop: '(min-width: 992px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  mobile: '(max-width: 767px)',
};
export const ResponsiveSection = styled(Section)`
  @media ${media.desktop} {
    padding: 45px 45px  45px 45px;
  text-align: center;
  height: 45vh;
    width: 100%;
    background: #444645;
    border-radius: 25px;
  }

  @media ${media.tablet} {
    padding: 35px 35px 35px 35px;
  text-align: center;
  height: 35vh;
    width: 100%;
    background: #444645;
    border-radius: 25px;

    
  }

  @media ${media.mobile} {
    padding: 25px 25px 25px 25px;
  text-align: center;
  height: 30vh;
    width: 100%;
    background: #444645;
    border-radius: 25px;
  }
`;

export const ResponsiveTitle = styled(Title)`
  @media ${media.desktop} {
    font-size: 45px;
  padding: 25px;
  color: #fff;
  }

  @media ${media.tablet} {
    font-size: 35px;
  padding: 20px;
  color: #fff;
  }

  @media ${media.mobile} {
    font-size: 30px;
  padding: 15px;
  color: #fff;
  }
`;

export const ResponsiveSubtitle = styled(Subtitle)`
  @media ${media.desktop} {
    font-size: 18px;
  padding: 25px;
  font-weight: normal;
  color: #fff;
  height: 100px;
  }

  @media ${media.tablet} {
    font-size: 14px;
  padding: 20px;
  font-weight: normal;
  color: #fff;
  height: 80px;
  }

  @media ${media.mobile} {
    font-size: 12px;
  padding: 15px;
  font-weight: normal;
  color: #fff;
  height: 70px;
  }
`;
export const ResponsiveButton = styled(Button)`
  @media ${media.desktop} {
    padding:15px 15px 15px 15px;
     
     line-height: 5em;
  }

  @media ${media.tablet} {
    padding:15px 15px 15px 15px;
     
     line-height: 3em;
  }

  @media ${media.mobile} {
    padding:10px 10px 10px 10px;
     line-height: 2.5em;
  }
`;



