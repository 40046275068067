import styled from 'styled-components';

export const Container = styled.div`
width: 100%;
height: 100%;
max-width:1300px;
margin:0 auto;
align-items: center;





@media (max-width: 767px) {
    {
      font-size: 16px;
      padding: 10px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    {
      font-size: 20px;
      padding: 30px;
    }
  }
  
  @media (min-width: 992px) {
    {
      font-size: 24px;
      padding: 40px;
    }
  }
  

`;

//Have one parent tag wrapping the whole component to ensure that responsiveness css is not overridden.
//We had a Section component as well as the Container component in the same page which messed up styling

//check font-size and padding




